.add-to-cart-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  // display: none;
  opacity: 0;
  visibility: hidden;
  // transform: scale(0.85);
  transition: 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  &.active {
    // display: block;
    opacity: 1;
    visibility: visible;
    // transform: scale(1);
    transition: 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    .modal-overlay {
      .modal {
        transform: translate(-50%, -50%);
        transition: 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
    }
  }
  .modal-overlay {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    position: relative;
    .modal {
      width: 70%;
      height: 600px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -20%);
      display: flex;
      background-color: white;
      border-radius: 6px;
      padding: 20px;
      transition: 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      &-left {
        display: flex;
        flex-direction: column;
        width: 50%;
        height: 100%;
        .swiper-main {
          width: 100%;
          height: 100%;
          margin-bottom: 10px;
          .swiper-button-next {
            background-color: rgba(0, 0, 0, 0.5);
            width: 40px;
            height: 40px;
            &::after {
              font-size: 17px;
            }
          }
          .swiper-button-prev {
            background-color: rgba(0, 0, 0, 0.5);
            width: 40px;
            height: 40px;
            &::after {
              font-size: 17px;
            }
          }
          .swiper-wrapper {
            .swiper-slide {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                display: block;
                width: 100%;
                // height: 100%;
                object-fit: cover;
                transform: unset;
                transition: unset;
              }
              &-active {
                filter: unset;
                img {
                  transform: unset;
                }
              }
            }
          }
        }
        .swiper-thumb {
          width: 100%;
          height: 100px;

          .swiper-wrapper {
            .swiper-slide {
              filter: grayscale(100%) blur(2px);
              width: 100%;
              height: 100%;
              position: unset;
              img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform: unset;
                transition: unset;
              }
              &-active {
                filter: unset;
                img {
                  transform: unset;
                }
              }
            }
          }
        }
      }
      &-right {
        // flex: 1;
        width: 50%;
        height: 100%;
        padding: 5px 15px;
        font-size: 20px;

        .brand-name {
          font-weight: bold;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .release-date {
            font-size: 14px;
            font-style: italic;
            font-weight: 400;
          }
        }

        .shoes-name {
          margin-top: 15px;
          font-weight: bold;
        }

        .shoes-prices {
          margin-top: 15px;
        }
        .shoes-code {
          margin-top: 15px;
        }
        .product-sizes {
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          font-size: 20px;
          .list-sizes {
            margin: 10px 0;
            display: flex;
            flex-wrap: wrap;
            li {
              cursor: pointer;
              margin: 8px 8px 8px 0;
              // padding: 8px 22px;
              width: 80px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 2px solid black;
              span {
                font-size: 20px;
              }
              &.active {
                background-color: black;
                color: white;
                span {
                  color: white;
                }
              }
            }
          }
        }

        .shoes-colorway {
          margin-top: 15px;
          font-weight: bold;
        }

        .product-control {
          margin-top: 10px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          height: 50px;
          .product-quantity {
            margin: 5px 50px 5px 0;
            // margin-right: 50px;
            display: flex;
            // flex-wrap: wrap;
            align-items: center;
            height: 100%;
            button {
              background-color: #fff;
              border-radius: 4px;
              border: 1px solid black;
              width: 50px;
              height: 100%;
              cursor: pointer;
              font-size: 35px;
              p {
                transition: 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
              }
              &:active {
                p {
                  transform: scale(0.8);
                }
              }
              &:nth-child(1) {
              }
              &:nth-child(2) {
              }
            }
            input {
              height: 100%;
              border: 1px solid black;
              border-left: unset;
              border-right: unset;
              text-align: center;
              font-size: 20px;
              outline: none;
              width: 100px;
            }
            .sub-quantity {
              border-right: unset;
              border-top-right-radius: unset;
              border-bottom-right-radius: unset;
            }
            .add-quantity {
              background-color: white;
              border-left: unset;
              border-top-left-radius: unset;
              border-bottom-left-radius: unset;
            }
          }

          .product-order {
            margin: 5px 0;
            width: 200px;
            height: 100%;
            background-color: rgba(151, 151, 151, 1);
            border-radius: 4px;
            // border: 1px solid black;
            border: unset;
            cursor: pointer;
            &:active {
              p {
                transform: scale(0.8);
              }
            }
            p {
              color: white;
              font-size: 16px;
              transition: 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
            }
          }
        }
      }

      .close-modal {
        position: absolute;
        top: -20px;
        right: -20px;
        // padding: 15px 25px;
        width: 40px;
        height: 40px;

        background-color: #fff;
        border-radius: 50%;
        border: 1px solid white;
        cursor: pointer;
        &:active {
          svg {
            transform: scale(0.8);
          }
        }
        svg {
          width: 100%;
          height: 100%;
          padding: 5px;
          transition: 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
        }
      }
    }
  }
}
