@import "../../Resources/Cores/layout";

@keyframes testBorderRadius {
  from {
    border-top: 0px solid black;
    border-right: 0px solid black;
    border-bottom: 0px solid black;
    border-left: 0px solid black;
  }
  to {
    border-top: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
  }
}
b {
  font-weight: bold;
}
.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  &-top {
    height: 50px;
    display: flex;
    align-items: center;
    background-color: black;
    color: white;
    .container {
      height: 100%;
    }
    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
    }
    &__left {
      display: flex;
      align-items: center;
      height: 100%;
      .seperate {
        background-color: white;
        width: 2px;
        height: 50%;
        margin: 0 10px;
      }
    }
    &__right {
      height: 100%;
      display: flex;
      align-items: center;
      a {
        text-decoration: none;
        color: white;
        font-weight: bold;
        transition: 0.4s ease-out;
        opacity: 1;
        height: 100%;
        padding: 5px 8px;
        img {
          width: 30px;
          height: 30px;
          margin-right: 18px;
          padding: 3px;
          border-radius: 50%;

          border-top: 0px solid black;
          border-right: 0px solid black;
          border-bottom: 0px solid black;
          border-left: 0px solid black;
          &.img-default {
            display: block;
          }
          &.img-hover {
            display: none;
          }
        }
        &:hover {
          background-color: white;
          color: black;
          .cart-total-item {
            background-color: black;
            color: white;
          }
          img {
            border-top: 01px solid black;
            border-right: 01px solid black;
            border-bottom: 01px solid black;
            border-left: 01px solid black;
            &.img-default {
              display: none;
            }
            &.img-hover {
              display: block;
            }
          }
        }
      }

      .login {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .cart {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__wrapper {
          position: relative;

          .cart-total-item {
            position: absolute;
            display: inline-block;
            top: -5px;
            font-size: 13px;
            padding: 4px 6px;
            background-color: white;
            color: black;
            border-radius: 50%;
            height: 20px;
            left: 15px;
          }
        }
      }
    }
  }
  &-main {
    flex: 1;
    .container {
      display: flex;
      align-items: center;
      position: relative;
      #search-product {
        height: 100%;
      }

      .search-box {
        position: relative;
        .products-search {
          z-index: 3;
          position: absolute;
          top: 105%;
          left: 0;
          width: 100%;
          background-color: #fff;
          max-height: 350px;
          overflow-y: auto;
          /* width */
          &::-webkit-scrollbar {
            width: 7px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 4px;
          }
          .products-search__item {
            width: 100%;
            &.error {
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: rgba(0, 0, 0, 0.05);
              height: 50px;
              h2 {
                font-weight: bold;
                font-size: 17px;
              }
            }
            &.loading {
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: rgba(0, 0, 0, 0.05);
              border: 1px solid rgba(0, 0, 0, 0.2);
              border-radius: 4px;
              height: 120px;
              position: relative;
              .lds-ripple {
                width: 60px;
                height: 60px;
              }
            }
            .product-search__item {
              padding: 5px;
              width: 100%;
              display: flex;
              border-bottom: 1px solid rgba(0, 0, 0, 0.2);
              transition: 0.4s ease-out;
              cursor: pointer;
              &:hover {
                background-color: rgba(0, 0, 0, 0.05);
              }
              .img {
                width: 40%;
                height: 100px;
                position: relative;
                margin-right: 10px;
                img {
                  max-width: 100%;
                  height: auto;
                  object-fit: cover;
                  position: absolute;
                  top: 50%;
                  left: 0;
                  transform: translateY(-50%);
                }
              }
              .content {
                width: 60%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                &-brand {
                  margin-bottom: 5px;
                  font-size: 15px;
                }
                &-productName {
                  margin-bottom: 8px;
                  font-size: 16px;
                  b {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    // Addition lines for 2 line or multiline ellipsis
                    display: -webkit-box !important;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    white-space: normal;
                  }
                }
                &-color {
                  margin-bottom: 5px;
                  font-size: 13px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  // Addition lines for 2 line or multiline ellipsis
                  display: -webkit-box !important;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  white-space: normal;
                }
              }
            }
          }
        }
      }
      .hamburger-responsive {
        display: none;
      }
      .search-box-responsive {
        display: none;
        width: 50px;
        height: 50px;
        background-color: black;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: 0.3s ease-out;
        &:hover {
          border-radius: 0%;
        }
      }
    }
  }

  &-logo {
    width: 150px;
    height: 150px;
    width: 250px;
    cursor: pointer;
    a {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 150px;
      height: auto;
      object-fit: cover;
    }
  }
  &-nav {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    &__item {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      a {
        padding: 15px 20px;
        text-decoration: none;
        color: black;
        font-size: 18px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;
        font-family: "pronobold";
        &.header-nav__link {
          &::after {
            position: absolute;
            content: "";
            display: block;
            width: 0px;
            height: 2px;
            top: 0;
            left: 0;
            background-color: black;
            transition: 0.4s ease-out;
          }
          &::before {
            position: absolute;
            content: "";
            display: block;
            width: 0px;
            height: 2px;
            bottom: 0;
            right: 0;
            background-color: black;
            transition: 0.4s ease-out;
          }
          &:hover {
            &::after {
              width: 50px;
            }
            &::before {
              width: 50px;
            }
          }
        }
      }

      &:hover {
        .header-nav__dropdown {
          opacity: 1;
          visibility: visible;
          transform: rotateX(0deg) translate(-50%, 0%);
        }
      }
      .header-nav__dropdown {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        width: calc(100% - 20px);
        top: 100%;
        left: 50%;
        display: flex;
        transition: 0.4s ease-in-out;
        transform: rotateX(90deg) translate(-50%, 0%);
        transform-origin: top center;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background-color: #fafafa;
        z-index: 999;
        height: 100%;
        &::after {
          position: absolute;
          display: block;
          content: "";
          width: 100%;
          height: 40%;
          background-color: transparent;
          top: -40%;
          left: 0%;
        }
        .dropdown-col {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: calc(100% / 4);
          .dropdown-col__item {
            padding: 20px 45px;
            display: flex;
            justify-content: center;
            a {
              position: relative;
              padding: 0;
              width: unset;
              cursor: pointer;
              &:hover {
                &::after {
                  width: 120%;
                }
                span {
                  transform: scale(0.9) translateY(-5px);
                }
              }
              span {
                display: flex;
                justify-content: center;
                transition: 0.3s ease-out;
              }
              &::after {
                position: absolute;
                display: block;
                content: "";
                width: 0%;
                height: 2px;
                background-color: black;
                top: 100%;
                left: 50%;
                transform: translate(-50%, 0%);
                transition: 0.3s ease-out;
              }
            }
          }
        }
      }
    }
  }
}

@include md {
  .header {
    &-main {
      .container {
        .search-box {
          display: none;
        }
        .search-box-responsive {
          display: flex;
        }
      }
    }
    &-logo {
      width: 150px;
    }
    &-nav {
      &__item {
        a {
          padding: 15px 25px;
        }
      }
    }
  }
}

@include sm {
  .header {
    &-main {
      .container {
        display: flex;
        justify-content: space-between;
        .header-nav {
          display: none;
        }
        .responsive {
          display: flex;
          .hamburger-responsive {
            margin-right: 20px;
            width: 50px;
            display: block;
            .hamburger {
              width: 100%;
              height: 100%;
              overflow: hidden;
              display: flex;
              justify-content: center;
              flex-direction: column;
              align-items: flex-end;
              cursor: pointer;
              &.open {
                span {
                  transition: 0.5s;
                  &:nth-child(1) {
                    width: 100%;
                    transform: rotateZ(135deg) translate(8px, -10px);
                  }
                  &:nth-child(2) {
                    transform: translateX(120%);
                  }
                  &:nth-child(3) {
                    width: 100%;
                    transform: rotateZ(45deg) translate(-8px, -10px);
                  }
                }
              }
              &:hover {
                span {
                  &:nth-child(1) {
                    width: 100%;
                  }
                  &:nth-child(2) {
                    width: 100%;
                  }
                  &:nth-child(3) {
                    width: 100%;
                  }
                }
              }
              span {
                margin: 5px 0;
                transition: 0.4s;
                height: 2px;
                background-color: black;
                &:nth-child(1) {
                  width: 30%;
                }
                &:nth-child(2) {
                  width: 50%;
                }
                &:nth-child(3) {
                  width: 80%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include xs {
  .header-logo {
    img {
      width: 130px;
    }
  }
  .header-top__wrapper {
    justify-content: center;
  }
  .header-top__left {
    display: none;
  }
}
