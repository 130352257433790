@import "../../Resources/Cores/layout";
.section {
  width: 100%;
  &.adidas-products {
    .swiper {
      width: 100%;
      height: 100%;
      .container {
        height: 100%;
      }
    }
  }
  &.tab-products {
    padding: 50px 0;
    width: 100%;
    .container {
      width: 100%;

      .tabs {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        &-header {
          display: flex;
          background-color: #000;
          border-radius: 30px;
          position: relative;
          overflow: hidden;
          box-shadow: 0 0 0 2px #333;
          z-index: 1;
          margin-bottom: 50px;
          &__item {
            cursor: pointer;
            width: 150px;
            height: 100%;
            &:nth-child(1) {
              border-top-left-radius: 30px;
              border-bottom-left-radius: 30px;
              &.active {
                h3 {
                  color: black;
                }
                ~ {
                  .glider {
                    left: 0;
                    opacity: 1;
                  }
                }
              }
            }
            &:nth-child(2) {
              &.active {
                h3 {
                  color: black;
                }
                ~ {
                  .glider {
                    left: 150px;
                    opacity: 1;
                  }
                }
              }
            }
            &:nth-child(3) {
              border-top-right-radius: 30px;
              border-bottom-right-radius: 30px;
              &.active {
                h3 {
                  color: black;
                }
                ~ {
                  .glider {
                    left: 300px;
                    opacity: 1;
                  }
                }
              }
            }

            h3 {
              text-align: center;
              padding: 15px 25px;
              transition: 0.4s linear;
              font-size: 23px;
              font-weight: bold;
              color: white;
              &:hover {
              }
            }

            &.glider {
              position: absolute;
              width: 150px;
              height: 100%;
              top: 0;
              left: 0;
              background-color: #fff;

              z-index: -1;
              transition: 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
              opacity: 0;
            }
          }
        }

        &-table {
          &__item {
            &.active {
              opacity: 1;
              visibility: visible;
              height: auto;
              transition: 0.4s cubic-bezier(0.755, 0.05, 0.855, 0.06);
            }
            height: 0;
            opacity: 0;
            visibility: hidden;
            transition: 0s;
          }
        }
      }
    }
  }
  .section-product {
    width: 100%;
    padding: 50px 0;
    .container {
      display: flex;
      justify-content: center;
      h2 {
        font-size: 35px;
        font-weight: bold;
      }
    }
  }
}

@include xxs {
  .section {
    width: 100%;
    &.adidas-products {
      .swiper {
        width: 100%;
        height: 100%;
        .container {
          height: 100%;
        }
      }
    }
    &.tab-products {
      .container {
        .tabs {
          &-header {
            &__item {
              width: 120px;
              &:nth-child(1) {
                &.active {
                  h3 {
                  }
                  ~ {
                    .glider {
                      left: 0;
                      opacity: 1;
                    }
                  }
                }
              }
              &:nth-child(2) {
                &.active {
                  h3 {
                  }
                  ~ {
                    .glider {
                      left: 120px;
                      opacity: 1;
                    }
                  }
                }
              }
              &:nth-child(3) {
                &.active {
                  h3 {
                    color: black;
                  }
                  ~ {
                    .glider {
                      left: 240px;
                      opacity: 1;
                    }
                  }
                }
              }

              h3 {
                text-align: center;
                padding: 15px 25px;
                transition: 0.4s linear;
                font-size: 20px;
                font-weight: bold;
                color: white;
                &:hover {
                }
              }

              &.glider {
                position: absolute;
                width: 120px;
                height: 100%;
                top: 0;
                left: 0;
                background-color: #fff;

                z-index: -1;
                transition: 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
                opacity: 0;
              }
            }
          }

          &-table {
            &__item {
              &.active {
                opacity: 1;
                visibility: visible;
                height: auto;
                transition: 0.4s cubic-bezier(0.755, 0.05, 0.855, 0.06);
              }
              height: 0;
              opacity: 0;
              visibility: hidden;
              transition: 0s;
            }
          }
        }
      }
    }
    .section-product {
      width: 100%;
      padding: 50px 0;
      .container {
        display: flex;
        justify-content: center;
        h2 {
          font-size: 35px;
          font-weight: bold;
        }
      }
    }
  }
}
