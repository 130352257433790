.product-info {
    margin-top: 25px;
    width: 100%;
    .container {
        width: 100%;
        display: flex;
        .product-info {
            &__left {
                width: 50%;
                display: flex;
                flex-direction: row-reverse;
                .product-info__swiper {
                    flex: 1;
                    border-radius: 22px;
                    .swiper-button-next {
                        background-color: rgba(0, 0, 0, 0.5);
                        width: 40px;
                        height: 40px;
                        &::after {
                            font-size: 17px;
                        }
                    }
                    .swiper-button-prev {
                        background-color: rgba(0, 0, 0, 0.5);
                        width: 40px;
                        height: 40px;
                        &::after {
                            font-size: 17px;
                        }
                    }
                    .swiper-wrapper {
                        .swiper-slide {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            img {
                                max-width: 100%;
                                height: auto;
                                // width: 100%;
                                // height: 100%;
                                object-fit: cover;
                                transform: unset;
                                transition: unset;
                                border-radius: 22px;
                            }
                            &-active {
                                img {
                                    transform: unset;
                                }
                            }
                        }
                    }
                }

                .product-info__swiperThumb {
                    width: 120px;
                    margin-right: 15px;
                    border-radius: 22px;
                    .swiper-wrapper {
                        width: 100%;
                        height: 100px;
                        display: flex;
                        flex-direction: column;
                        .swiper-slide {
                            // width: 100% !important;
                            // height: 100% !important;
                            img {
                                border-radius: 22px;
                                // width: 100%;
                                // height: 100%;
                                max-width: 100%;
                                height: auto;
                                object-fit: cover;
                                transform: unset;
                                transition: unset;
                                filter: grayscale(100%) blur(3px);
                            }

                            &-active {
                                img {
                                    filter: grayscale(0%) blur(0px);
                                }
                            }
                        }
                    }
                }
            }

            &__right {
                width: 50%;
                padding: 5px 15px;
                .product {
                    &-brand {
                        font-weight: bold;
                        font-size: 20px;
                        text-transform: uppercase;
                        display: flex;
                        justify-content: space-between;
                        .date-release {
                            font-size: 14px;
                            font-weight: 400;
                            font-style: italic;
                        }
                    }
                    &-name {
                        margin-top: 10px;
                        font-size: 28px;
                        text-transform: uppercase;
                        font-weight: bold;
                    }
                    &-colorway {
                        margin-top: 10px;
                        font-size: 28px;
                        text-transform: uppercase;
                        span {
                            font-weight: bold;
                        }
                    }
                    &-price {
                        margin-top: 10px;
                        font-size: 20px;
                        display: flex;
                    }

                    &-code {
                        font-size: 20px;
                    }

                    &-sizes {
                        margin-top: 10px;
                        display: flex;
                        flex-direction: column;
                        font-size: 20px;
                        .list-sizes {
                            margin: 10px 0;
                            display: flex;
                            li {
                                cursor: pointer;
                                margin: 0 8px 0 0;
                                // padding: 8px 22px;
                                width: 80px;
                                height: 40px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border: 2px solid black;
                                span {
                                    font-size: 20px;
                                }
                                &.active {
                                    background-color: black;
                                    color: white;
                                    span {
                                        color: white;
                                    }
                                }
                            }
                        }
                    }

                    &-control {
                        margin-top: 10px;
                        display: flex;
                        align-items: center;
                        height: 50px;
                        .product-quantity {
                            margin-right: 50px;
                            display: flex;
                            align-items: center;
                            height: 100%;
                            button {
                                background-color: #fff;
                                border-radius: 4px;
                                border: 1px solid black;
                                width: 50px;
                                height: 100%;
                                cursor: pointer;
                                font-size: 35px;
                                p {
                                    transition: 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
                                }
                                &:active {
                                    p {
                                        transform: scale(0.8);
                                    }
                                }
                                &:nth-child(1) {
                                }
                                &:nth-child(2) {
                                }
                            }
                            input {
                                height: 100%;
                                border: 1px solid black;
                                border-left: unset;
                                border-right: unset;
                                text-align: center;
                                font-size: 20px;
                                outline: none;
                                width: 100px;
                            }
                            .sub-quantity {
                                border-right: unset;
                                border-top-right-radius: unset;
                                border-bottom-right-radius: unset;
                            }
                            .add-quantity {
                                background-color: white;
                                border-left: unset;
                                border-top-left-radius: unset;
                                border-bottom-left-radius: unset;
                            }
                        }

                        .product-order {
                            width: 200px;
                            height: 100%;
                            background-color: rgba(151, 151, 151, 1);
                            border-radius: 4px;
                            // border: 1px solid black;
                            border: unset;
                            cursor: pointer;
                            &:active {
                                p {
                                    transform: scale(0.8);
                                }
                            }
                            p {
                                color: white;
                                font-size: 16px;
                                transition: 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
                            }
                        }
                    }

                    &-desc {
                        display: flex;
                        flex-direction: column;
                        p {
                            font-size: 20px;
                            &:nth-child(1) {
                                margin-bottom: 15px;
                            }
                            &:nth-child(2) {
                                font-size: 18px;
                                line-height: 25px;
                            }
                        }
                    }
                }

                .line {
                    width: 100%;
                    height: 0.2em;
                    background-color: black;
                    margin: 25px 0;
                }
            }
        }
    }
}
