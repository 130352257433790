@import "../../Resources/Cores/layout";

.product-list {
  .container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    // grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    row-gap: 30px;
  }
}
