@import "../../Resources/Cores/layout";
.swiper-container {
  cursor: pointer;
  &:hover {
    .swiper-button-prev {
      visibility: visible;
      opacity: 1;
      transform: translateX(10px);
    }
    .swiper-button-next {
      visibility: visible;
      opacity: 1;
      transform: translateX(-10px);
    }
  }
  .swiper-button-prev {
    visibility: hidden;
    opacity: 0;
    color: white;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    position: absolute;
    transition: 0.4s ease-out, transform 0.6s ease-out;
    border: 2px solid white;
    transform: translateX(80px);
    &::after {
      position: absolute;
      left: 44%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.6);
    }
  }

  .swiper-button-next {
    visibility: hidden;
    opacity: 0;
    color: white;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    position: absolute;
    transition: 0.4s ease-out, transform 0.6s ease-out;
    border: 2px solid white;
    transform: translateX(-80px);
    &::after {
      position: absolute;
      left: 56%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.6);
    }
  }

  .swiper-pagination {
    .swiper-pagination-bullet {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.4);
      opacity: 1;
      margin: 0 6px;
      &-active {
        background-color: rgba(255, 255, 255, 0.8);
        opacity: 1;
      }
    }
  }
  .swiper-pagination {
    bottom: 20px;
  }
  .swiper-slide {
    img {
      max-width: 100%;
      height: auto;
      object-fit: cover;
      transform: scale(1);
      transition: 4s linear;
    }

    &-active {
      img {
        transform: scale(1.1);
      }
    }
  }
}

@keyframes swiperSlideActive {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}
@include md {
  .swiper-container {
    .swiper-button-prev {
      width: 50px;
      height: 50px;
      &::after {
        font-size: 25px;
      }
    }
    .swiper-button-next {
      width: 50px;
      height: 50px;
      &::after {
        font-size: 25px;
      }
    }
  }
}
