@import "../../Resources/Cores/variables";
@import "../../Resources/Cores/mixins";
html,
body {
  font-size: 16px;
  font-family: "prono";
  height: 100%;
  &.open-modal {
    overflow-y: hidden;
  }
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .container-fluid {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    margin-right: auto;
    margin-left: auto;
  }
  .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  @media (min-width: $xxs-min) {
    .container {
      max-width: 540px;
    }
  }
  @media (min-width: $xs-min) {
    .container {
      max-width: 720px;
    }
  }
  @media (min-width: $sm-min) {
    .container {
      max-width: 960px;
    }
  }
  @media (min-width: $md-min) {
    .container {
      max-width: $container-width;
    }
  }
  @include lg {
    // CSS responsive large desktop
  }
  @include md {
    // CSS responsive desktop
  }
  @include sm {
    // CSS responsive tablet
  }
  @include xs {
    // CSS responsive mobile
  }
  @include xxs {
    // CSS responsive small mobile
  }
}

.lds-ripple {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.lds-ripple div {
  position: absolute;
  // border: 4px solid #b9a16b;
  background-color: black;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
