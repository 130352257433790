@import "../../Resources/Cores/layout";
.credit-card {
  width: 100%;
  .container {
    width: calc(100% - 30px);
    display: flex;
    .card {
      width: 25%;
      display: flex;
      align-items: center;
      padding: 15px 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      box-shadow: 0px 6px 7px -3px rgba(0, 0, 0, 0.3);
      .img {
        display: flex;
        align-items: center;
        margin-right: 15px;
        svg {
          width: 40px;
          height: 40px;
        }
      }
      .content {
        font-family: "Arial";
        font-weight: 600;
        font-size: 15px;
        span {
          color: #b9a16b;
        }
      }
    }
  }
}
@include sm {
  .credit-card {
    .container {
      flex-direction: column;
      .card {
        width: 100%;
        box-shadow: unset;
        padding: 10px 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        &:last-child {
          box-shadow: 0px 6px 7px -3px rgba(0, 0, 0, 0.3);
        }
        .img {
          svg {
            width: 30px;
            height: 30px;
          }
        }
        .content {
          font-size: 14px;
        }
      }
    }
  }
}
