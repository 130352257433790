@import "../../Resources/Cores/layout";
.product-swiper {
  width: 100%;
  height: 100%;
  .swiper-slide {
    margin: 10px 0;
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-pagination-progressbar {
    top: unset;
    left: unset;
    bottom: 0;
    .swiper-pagination-progressbar-fill {
      background: var(--swiper-pagination-color, black);
    }
  }
}

@include md {
  .product-swiper {
    .swiper-slide {
      // margin: 20px 20px 20px 0 !important;
    }
    .swiper-slide img {
    }
    .swiper-pagination-progressbar {
      .swiper-pagination-progressbar-fill {
      }
    }
  }
}

@include sm {
}

@include xs {
  .product-swiper {
    height: auto;
    .swiper-slide {
      display: flex;
      justify-content: center;
      // height: 200px !important;
    }
    .swiper-slide img {
    }
    .swiper-pagination-progressbar {
      .swiper-pagination-progressbar-fill {
      }
    }
  }
}
