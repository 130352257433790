@import "../../Resources/Cores/layout";

.product-swiper__item {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    &:hover {
        .img {
            img {
                transform: scale(1.25);
            }
            .action-list {
                .action-item {
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0px);
                    svg {
                    }
                }
            }
        }
    }
    .img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 310px;
        position: relative;
        overflow: hidden;
        padding: 15px;
        border-radius: 8px;
        .action-list {
            width: 100%;
            position: absolute;
            bottom: 0;
            display: flex;
            padding: 10px;
            justify-content: center;
            .action-item {
                opacity: 0;
                visibility: hidden;
                position: relative;
                margin: 0 8px;
                padding: 5px;
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background-color: black;
                transition-duration: 0.4s;
                transition-timing-function: cubic-bezier(0.86, 0.7, 0.25, 0.9);
                transform: translateY(50px);
                &:nth-child(1) {
                    transition-delay: 0s;
                }
                &:nth-child(2) {
                    transition-delay: 0.1s;
                }
                &:nth-child(3) {
                    transition-delay: 0.2s;
                }
                &:hover {
                    a {
                        transform: translate(-50%, 0%) scale(1);
                        opacity: 1;
                        visibility: visible;
                        border-radius: 4px;
                        transition: 0.4s ease-out;
                    }
                }
                svg {
                    width: 30px;
                    height: 30px;
                    transition: 0.4s ease-out;
                    fill: white;
                }
                &:hover {
                    border-radius: 5px;
                    svg {
                    }
                }

                a {
                    opacity: 0;
                    visibility: visible;
                    position: absolute;
                    top: -90%;
                    left: 50%;
                    transform: translate(-50%, 50%) scale(0.8);
                    border-radius: 50%;
                    display: inline-block;
                    text-decoration: none;
                    color: white;
                    background-color: #333;
                    white-space: nowrap;
                    padding: 8px;
                    font-size: 16px;
                    transition: 0s ease-out;

                    &::after {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 95%;
                        left: 50%;
                        border-style: solid;
                        border-width: 10px;
                        transform: translate(-50%, 0%);
                        border-color: #333 transparent transparent transparent;
                    }
                }
            }
        }
        img {
            max-width: 100%;
            height: auto;
            object-fit: cover;
            transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
            transform-origin: bottom;
            //   position: absolute;
            //   width: 100%;
            //   left: 0;
            //   top: 0;
            //   height: 100%;
            //   object-fit: cover;
        }
    }
    .content {
        // border-top: 1px solid rgba(0, 0, 0, 0.1);
        flex: 1;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .product-name {
            font-size: 18px;
            height: 25px;
            color: #333;
            h3 {
                font-weight: bold;
                display: -webkit-box;
                max-width: 100%;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
        .price {
            margin-top: 15px;
            font-size: 16px;
            h2 {
            }
        }
    }
}

@include md {
    .product-swiper__item {
        .img {
            height: 250px;
        }
    }
}

@include sm {
}

@include xs {
    .product-swiper__item {
        width: 80%;
        .img {
            height: 250px;
        }
    }
}
