.wave-line {
  width: 100%;
  margin-top: 20px;
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .wave {
      width: 100px;
      height: 50px;
      background: transparent;
      position: relative;
      overflow: hidden;
      &::before {
        content: "aaaaaaaaaaa";
        position: absolute;
        font-size: 35px;
        color: transparent;
        text-decoration-style: wavy;
        text-decoration-color: black;
        text-decoration-line: underline;
        display: flex;
        align-items: center;
        transform: translateY(-50%);
        top: 10px;
        left: 10px;
        animation: waveLineAnimate 1s linear infinite;
      }
    }
  }
}

@keyframes waveLineAnimate {
  0% {
    transform: translateX(-12px);
  }
  100% {
    transform: translateX(-45px);
  }
}
