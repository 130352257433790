*{
  box-sizing: border-box;
}
body{
  font-family: 'Roboto';
  font-size: 18px;
}
.App {
  width: 100%;
  height: 100%;
}

