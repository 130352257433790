@import "../../Resources/Cores/layout";

.box-list {
  width: 100%;
  // margin: 60px 0 120px 0;
  padding: 60px 0 120px 0;
  .container {
    display: flex;
    justify-content: space-around;
    -webkit-box-reflect: below 1px
      linear-gradient(transparent, transparent, transparent, #0004);
    .box {
      position: relative;
      width: 320px;
      height: 400px;
      background-color: #444;
      border-radius: 20px;
      cursor: pointer;
      background: linear-gradient(
        45deg,
        #b9a16b 25%,
        #444 25%,
        #444 50%,
        #b9a16b 50%,
        #b9a16b 75%,
        #444 75%,
        #444 100%
      );
      background-size: 40px 40px;
      filter: grayscale(1);
      transition: filter 1s;
      animation: animateBg 0.5s linear infinite;
      animation-play-state: paused;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 100%;
        height: auto;
        object-fit: cover;
        transform: translateY(0%) scale(1);
        transition: 0.4s ease-out;
      }
      &:hover {
        animation-play-state: running;
        filter: grayscale(0);
        img {
          transform: translateY(-20%) scale(1.1);
        }
      }

      &:nth-child(2) {
        background: linear-gradient(
          135deg,
          #ff61ae 25%,
          #444 25%,
          #444 50%,
          #ff61ae 50%,
          #ff61ae 75%,
          #444 75%,
          #444 100%
        );
        background-size: 40px 40px;
      }
      &:nth-child(3) {
        background: linear-gradient(
          45deg,
          #2da2ff 25%,
          #444 25%,
          #444 50%,
          #2da2ff 50%,
          #2da2ff 75%,
          #444 75%,
          #444 100%
        );
        background-size: 40px 40px;
      }
    }
  }
}

@keyframes animateBg {
  0% {
    background-position: 0;
  }
  100% {
    background-position: 40px;
  }
}

@include md {
  .box-list {
    .container {
      .box {
        width: 280px;
        height: 360px;
      }
    }
  }
}

@include sm {
  .box-list {
    .container {
      .box {
        width: 210px;
        height: 280px;
      }
    }
  }
}

@include xs {
  .box-list {
    margin: 30px 0;
    .container {
      flex-direction: column;
      align-items: center;
      -webkit-box-reflect: unset;
      .box {
        margin: 25px 0;
        width: 60%;
        height: 400px;
      }
    }
  }
}
