@import "../../Resources/Cores/variables";
@import "../../Resources/Cores/mixins";

.navbar-responsive {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-100%);
  transition: 0.3s ease-out;
  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0%);
  }
  .navbar-wrapper {
    width: 100%;
    height: 100%;
    background-color: white;
    .navbar-header {
      width: 100%;
      .navbar-control__close {
        display: flex;
        justify-content: flex-end;
        padding: 20px 30px;
        .btn-close {
          border-radius: 50%;
          border: 9px solid transparent;
          color: #777;
          width: 56px;
          height: 56px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          position: relative;
          background: rgba(0, 0, 0, 0.05);
          cursor: pointer;
          &:hover {
            &::after {
              background-color: #b9a16b;
              transform: rotate(0deg);
            }
            &::before {
              background-color: #b9a16b;
              transform: rotate(180deg);
            }
          }
          &::after {
            content: "";
            position: absolute;
            height: 2px;
            width: 100%;
            top: 50%;
            left: 0;
            margin-top: -1px;
            background-color: #777;
            transform: rotate(45deg);
            transition: 0.3s ease-out;
          }
          &::before {
            content: "";
            position: absolute;
            height: 2px;
            width: 100%;
            top: 50%;
            left: 0;
            margin-top: -1px;
            background-color: #777;
            transform: rotate(135deg);
            transition: 0.3s ease-out;
          }
        }
      }
    }
    .navbar-search {
      padding: 20px 30px;
      position: relative;

      .products-search {
        z-index: 3;
        position: absolute;
        top: 105%;
        left: 50%;
        width: 95%;
        transform: translateX(-50%);
        background-color: #fff;
        max-height: 500px;
        overflow-y: auto;
        /* width */
        &::-webkit-scrollbar {
          width: 7px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 4px;
        }
        .products-search__item {
          width: 100%;
          &.error {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.05);
            height: 80px;
            h2 {
              font-weight: bold;
              font-size: 20px;
            }
          }
          &.loading {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.05);
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 4px;
            height: 120px;
            position: relative;
            .lds-ripple {
              width: 60px;
              height: 60px;
            }
          }
          .product-search__item {
            height: 150px;
            padding: 5px;
            width: 100%;
            display: flex;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            transition: 0.4s ease-out;
            cursor: pointer;
            &:hover {
              background-color: rgba(0, 0, 0, 0.05);
            }
            .img {
              width: 40%;
              height: 100%;
              position: relative;
              margin-right: 10px;
              img {
                height: 100%;
                object-fit: cover;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
            .content {
              width: 60%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              &-brand {
                margin-bottom: 10px;
                font-size: 19px;
              }
              &-productName {
                margin-bottom: 15px;
                font-size: 19px;
                b {
                  text-overflow: ellipsis;
                  overflow: hidden;
                  // Addition lines for 2 line or multiline ellipsis
                  display: -webkit-box !important;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                  white-space: normal;
                }
              }
              &-color {
                margin-bottom: 5px;
                font-size: 15px;
                text-overflow: ellipsis;
                overflow: hidden;
                // Addition lines for 2 line or multiline ellipsis
                display: -webkit-box !important;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                white-space: normal;
              }
            }
          }
        }
      }
    }
    .navbar-list {
      padding: 0 30px;
      &__item {
        padding: 20px 10px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        p {
          cursor: pointer;
          font-size: 20px;
          text-decoration: none;
          color: black;
        }
        .toggle {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          overflow: hidden;

          &.active {
            background-color: rgba(0, 0, 0, 0.05);
            &::after {
              transform: rotate(135deg) translate(-30px, -4px);
              opacity: 0;
              visibility: hidden;
            }
            &::before {
              transform: rotate(45deg) translate(-30px, 4px);
              opacity: 0;
              visibility: hidden;
            }
            span {
              opacity: 1;
              visibility: visible;
              &:nth-child(1) {
                position: absolute;
                width: 50%;
                height: 2px;
                background-color: #b9a16b;
                transform: translateX(20%);
              }
              &:nth-child(2) {
                position: absolute;
                width: 50%;
                height: 2px;
                background-color: #b9a16b;
                transform: translateX(-20%);
              }
            }
          }

          &:hover {
            background-color: rgba(0, 0, 0, 0.05);
            &::after {
              background-color: #b9a16b;
            }
            &::before {
              background-color: #b9a16b;
            }
          }
          span {
            transition: 0.3s ease-out;
            opacity: 0;
            visibility: hidden;
            &:nth-child(1) {
              position: absolute;
              width: 50%;
              height: 2px;
              background-color: black;
              transform: translateX(180%);
            }
            &:nth-child(2) {
              position: absolute;
              width: 50%;
              height: 2px;
              background-color: black;
              transform: translateX(-180%);
            }
          }
          &::after {
            position: absolute;
            display: block;
            content: "";
            width: 50%;
            height: 2px;
            background-color: rgba(0, 0, 0, 0.7);
            transform: rotate(135deg) translate(-4px, -4px);
            transition: 0.3s ease-out;
          }
          &::before {
            position: absolute;
            display: block;
            content: "";
            width: 50%;
            height: 2px;
            background-color: rgba(0, 0, 0, 0.7);
            transform: rotate(45deg) translate(-4px, 4px);
            transition: 0.3s ease-out;
          }
        }
      }
      .navbar-sublist {
        padding: 0 50px;
        &__item {
          cursor: pointer;
          font-size: 22px;
          text-decoration: none;
          color: black;
          padding: 15px 10px;
        }
      }
    }
  }
}

@include md {
}

@include sm {
}

@include xxs {
  .navbar-responsive {
    &.active {
    }
    .navbar-wrapper {
      .navbar-header {
        .navbar-control__close {
          .btn-close {
            &:hover {
              &::after {
              }
              &::before {
              }
            }
            &::after {
            }
            &::before {
            }
          }
        }
      }
      .navbar-search {
        .products-search {
          /* width */
          &::-webkit-scrollbar {
          }

          /* Track */
          &::-webkit-scrollbar-track {
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
          }
          .products-search__item {
            &.error {
              h2 {
              }
            }
            &.loading {
              .lds-ripple {
              }
            }
            .product-search__item {
              flex-direction: column;
              height: 300px;
              &:hover {
              }
              .img {
                width: 100%;
                img {
                }
              }
              .content {
                width: 100%;
                padding: 0 15px;
                &-brand {
                  p {
                    font-size: 23px;
                  }
                }
                &-productName {
                  b {
                    font-size: 23px;
                  }
                }
                &-color {
                  b {
                    font-size: 18px;
                  }
                }
              }
            }
          }
        }
      }
      .navbar-list {
        &__item {
          p {
          }
          .toggle {
            &.active {
              &::after {
              }
              &::before {
              }
              span {
                &:nth-child(1) {
                }
                &:nth-child(2) {
                }
              }
            }

            &:hover {
              &::after {
              }
              &::before {
              }
            }
            span {
              &:nth-child(1) {
              }
              &:nth-child(2) {
              }
            }
            &::after {
            }
            &::before {
            }
          }
        }
        .navbar-sublist {
          &__item {
          }
        }
      }
    }
  }
}
